import type { CreditCardListItem } from "@brm/schema-types/types.js"
import type { BRMPaths } from "@brm/type-helpers/paths.js"
import { Heading, HStack, Stack } from "@chakra-ui/react"
import { skipToken } from "@reduxjs/toolkit/query"
import { useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation } from "react-router-dom"
import { usePostCreditCardV1ListQuery, type PostCreditCardV1ListApiArg } from "../app/services/generated-api.js"
import EmptyTableState from "../components/DataTable/EmptyTableState.js"
import { useLocalStorageTableParamsSync } from "../components/DataTable/use-schema-table-params.js"
import {
  packageSortFilterOptionsForAPI,
  shownColumnsForTableParamState,
  TABLE_DEFAULT_PARAMS,
  type TableParamsState,
} from "../util/schema-table.js"
import { useObjectSchema } from "../util/use-schema.js"
import { CREDIT_CARDS_TABLE_ID } from "./constants.js"
import { CreditCard } from "./CreditCard.js"
import TablePageHeader from "./DataTable/SchemaFilter/TablePageHeader.js"

const defaultColumns: BRMPaths<CreditCardListItem>[] = ["display_name", "last_four"]

export default function CardList(
  props: Pick<PostCreditCardV1ListApiArg, "toolId" | "vendorId"> & {
    filterTransactions: (cardId: string) => void
  }
) {
  const intl = useIntl()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const creditCardSchema = useObjectSchema("CreditCard")
  const [cardToLoad, setCardToLoad] = useState<string | undefined>(undefined)

  useLocalStorageTableParamsSync(CREDIT_CARDS_TABLE_ID)
  const [tableParams, updateTableParams] = useState<TableParamsState<string>>({
    ...TABLE_DEFAULT_PARAMS,
    sorting: [{ id: "display_name", desc: true }],
  })
  const shownColumns = useMemo(
    () => tableParams && shownColumnsForTableParamState(tableParams, defaultColumns),
    [tableParams]
  )

  const apiParams = useMemo(
    () =>
      creditCardSchema && tableParams && packageSortFilterOptionsForAPI<string>(tableParams, creditCardSchema, intl),
    [intl, tableParams, creditCardSchema]
  )

  const { data, isFetching } = usePostCreditCardV1ListQuery(
    apiParams
      ? {
          toolId: props.toolId,
          vendorId: props.vendorId,
          listQueryStringParams: apiParams,
        }
      : skipToken
  )

  if (!tableParams || !creditCardSchema || !shownColumns) {
    return null
  }

  return (
    <Stack width="full" gap={4}>
      {data ? (
        <TablePageHeader
          title={intl.formatMessage(
            {
              id: "creditCard.tab.heading.withCount",
              description: "Heading for credit cards overview section",
              defaultMessage: "Credit Cards ({total})",
            },
            { total: data.total }
          )}
          tableId={CREDIT_CARDS_TABLE_ID}
          filterMap={tableParams.filterMap}
          onChangeFilters={(filterMap) => updateTableParams({ ...tableParams, filterMap })}
          objectSchema={creditCardSchema}
          selectedColumns={shownColumns}
        />
      ) : (
        <Heading size="xs">
          <FormattedMessage
            id="transaction.tab.heading"
            description="Heading for credit cards overview section"
            defaultMessage="Credit Cards"
          />
        </Heading>
      )}
      <HStack overflowX="auto">
        {data?.cards.map((card) => (
          <CreditCard
            key={card.id}
            {...card}
            filterTransactions={(cardId) => props.filterTransactions(cardId)}
            selected={queryParams.get("card.id") === card.id}
            // Only show loading state if the card is being locked or unlocked
            isFetching={isFetching && cardToLoad === card.id}
            onLock={() => setCardToLoad(card.id)}
            onUnlock={() => setCardToLoad(card.id)}
          />
        ))}
      </HStack>
      {!isFetching && tableParams.filterMap.size === 0 && !data && (
        <EmptyTableState
          emptyState={intl.formatMessage({
            id: "creditCard.tab.emptyState",
            description:
              "Text to display instead of the credit cards table when there are no credit cards for the tool",
            defaultMessage:
              "No credit cards found. This tool is free or your payment method is not integrated with BRM",
          })}
        />
      )}
    </Stack>
  )
}
