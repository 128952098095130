import type { DocumentMinimal, FormFieldConfigWithInternalOnly } from "@brm/schema-types/types.js"
import { Flags } from "@brm/util/flags.js"
import { isObject } from "@brm/util/type-guard.js"
import { chakra, Stack } from "@chakra-ui/react"
import type { JSONSchema } from "@json-schema-tools/meta-schema"
import { useFlags } from "launchdarkly-react-client-sdk"
import { useCallback, useRef, useState } from "react"
import type { DefaultValues, FieldValues, Path, SetValueConfig } from "react-hook-form"
import { useForm } from "react-hook-form"
import type { Except, ReadonlyDeep } from "type-fest"
import { usePostDocumentV1ByIdBackgroundExtractMutation } from "../../app/services/generated-api.js"
import { cleanValues } from "../../features/workflows/run/utils.js"
import { initializeReactHookFromState } from "../../util/form.js"
import { log } from "../../util/logger.js"
import type { DocumentChangeHandler } from "../Document/DocumentUpload.js"
import { DynamicFormField, type DynamicFormFieldProps } from "./DynamicFormField.js"

export interface DynamicFormProps<TFieldValues extends FieldValues> {
  initialFormValues: DefaultValues<TFieldValues> | undefined
  rootSchema: ReadonlyDeep<JSONSchema>
  formFields: (FormFieldConfigWithInternalOnly & Pick<DynamicFormFieldProps, "path">)[]
  onSubmit: (outputs: TFieldValues) => Promise<void>
  /** Set this to undefined if the given schema does not need to render any documents */
  documentDownloadURL: undefined | ((path: (string | number)[], document: DocumentMinimal) => string)
  isEditing: boolean
}

/**
 * Given a root schema and an output schema, DynamicForm creates a form that submits to the given submit function
 * onBlur whenever there is changes made so that the form can be submitted without the user having to click a button.
 *
 * DynamicForm
 *  └─ DynamicFormField
 *      └─ InputContainer
 *           └─ Input Component (NumberInput, StringInput, etc.)
 */
export default function DynamicForm<TFieldValues extends FieldValues>({
  initialFormValues,
  rootSchema,
  formFields,
  onSubmit,
  documentDownloadURL,
  isEditing,
}: DynamicFormProps<TFieldValues>) {
  const [uncontrolledComponentResetId, setUncontrolledComponentResetId] = useState(0)
  const incrementUncontrolledComponentResetId = useCallback(() => setUncontrolledComponentResetId((id) => id + 1), [])
  const form = useForm<TFieldValues>({ defaultValues: initialFormValues, shouldFocusError: false })
  initializeReactHookFromState(form)

  const { [Flags.DOCUMENT_EXTRACTION_V2_ENABLED]: documentExtractionV2Enabled } = useFlags()
  const [initializeExtraction] = usePostDocumentV1ByIdBackgroundExtractMutation()

  const formRef = useRef<HTMLFormElement>(null)

  const documentDownloadUrlCallback = useCallback(
    (path: (string | number)[], document: DocumentMinimal): string => {
      if (documentDownloadURL) {
        return documentDownloadURL(path, document)
      }
      log.error("No document download URL found", new Error("Unexpected document found on form"))
      // Returning empty string here will not allow the user to click the document
      return ""
    },
    [documentDownloadURL]
  )

  const onDocumentChange = useCallback<DocumentChangeHandler>(
    async (documents, type, document) => {
      formRef.current?.requestSubmit()
      if (
        type === "add" &&
        documentExtractionV2Enabled &&
        isObject(initialFormValues) &&
        "object_type" in initialFormValues &&
        initialFormValues.object_type === "Tool" &&
        initialFormValues.id
      ) {
        await initializeExtraction({
          id: document.id,
          documentBackgroundExtractionRequest: {
            intent_type: "field_upload",
            starting_relations: {
              object_type: "Tool",
              object_ids: [initialFormValues.id],
            },
          },
        }).unwrap()
      }
    },
    [documentExtractionV2Enabled, initializeExtraction, initialFormValues]
  )

  const getValue = useCallback(
    (path: string) => {
      // Need to handle an empty string path as undefined to make sure that react-hook-form returns the root object
      return form.watch((path || undefined) as Path<TFieldValues>)
    },
    [form]
  )

  const formFieldProps: Except<DynamicFormFieldProps, "path" | "formField"> = {
    control: form.control,
    getValue,
    setValue: form.setValue as (path: string, value: unknown, options?: SetValueConfig) => void,
    resetField: form.resetField as (path: string, options?: SetValueConfig) => void,
    rootBaseSchema: rootSchema,
    isReadOnly: !isEditing,
    onDocumentChange,
    uncontrolledComponentResetId,
    incrementUncontrolledComponentResetId,
    getDocumentDownloadUrl: documentDownloadUrlCallback,
  }

  return (
    <chakra.form
      // Submit on blur if changed
      onBlur={(event) => {
        if (isEditing && form.formState.isDirty) {
          event.currentTarget.requestSubmit()
        }
      }}
      onSubmit={form.handleSubmit(async (values) => {
        const cleanedValues = cleanValues(values, rootSchema)
        await onSubmit(cleanedValues as TFieldValues)
        form.reset(form.getValues(), { keepValues: true, keepDefaultValues: false, keepErrors: true })
      })}
      noValidate={true} // ensure react-hook-form validation is used
      ref={formRef}
    >
      <Stack gap={6}>
        {formFields.map((formField) => {
          return (
            <DynamicFormField
              {...formFieldProps}
              key={formField.path.join(".")}
              formField={formField}
              path={formField.path}
            />
          )
        })}
      </Stack>
    </chakra.form>
  )
}
