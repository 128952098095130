import { Box, Button, Container, Flex, HStack } from "@chakra-ui/react"
import { type FunctionComponent } from "react"
import { FormattedMessage } from "react-intl"
import { useParams } from "react-router-dom"
import { useGetUserV1WhoamiQuery } from "../../app/services/generated-api.js"
import { Link } from "../../components/Link.js"
import { useSubscribe } from "../../features/pubsub/subscribe.js"
import BrmTextLogo from "../BrmTextLogo.js"
import ProfileMenu from "../Nav/ProfileMenu.js"
import { HEADER_HEIGHT, NAVBAR_Z_INDEX } from "../Nav/constants.js"
import FixedHeightContainer from "./FixedHeightContainer.js"

export const LinkRouteContainer: FunctionComponent = () => {
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const { code } = useParams<{ code: string }>()

  useSubscribe(`${import.meta.env.VITE_API_BASE_URL}/subscribe/v1/links/${code}/workflow-run`, "LinkRouteContainer")

  return (
    <>
      <Box as="nav" bgColor="white" position="sticky" top={0} zIndex={NAVBAR_Z_INDEX} height={`${HEADER_HEIGHT}px`}>
        <Container py={3} px={6} maxWidth="100%" borderBottom="1px solid #EAECF0">
          <HStack justifyContent="space-between">
            <BrmTextLogo />
            {whoami ? (
              <ProfileMenu user={whoami} hideSettings />
            ) : (
              <Button as={Link} to={`/create_account/${code}`} colorScheme="brand" ml={4}>
                <FormattedMessage
                  id="linkRouteContainer.signin"
                  description="The button text for the sign in or sign up button"
                  defaultMessage="Sign in or sign up"
                />
              </Button>
            )}
          </HStack>
        </Container>
      </Box>
      <Flex flexGrow={1} minHeight={0} direction="column">
        <FixedHeightContainer />
      </Flex>
    </>
  )
}
