import { isRichTextType } from "@brm/schema-helpers/rich-text/rich-text.js"
import { ROLES_BY_PERMISSION, hasPermission } from "@brm/schema-helpers/role.js"
import { getCurrentApprovalStep, workflowRunIsActive, workflowRunStepIsActive } from "@brm/schema-helpers/workflow.js"
import type {
  Permission,
  Role,
  UserWithOrganization,
  WorkflowRun,
  WorkflowRunStepStatus,
  WorkflowStepStandardType,
} from "@brm/schema-types/types.js"
import { type WorkflowRunStep } from "@brm/schema-types/types.js"
import { isObjectWithIdAndObjectType } from "@brm/type-helpers/object.js"
import { getSchemaAtPath } from "@brm/util/schema.js"
import { unreachable } from "@brm/util/unreachable.js"
import { Temporal } from "@js-temporal/polyfill"
import type { JSONSchema } from "@json-schema-tools/meta-schema"
import { Traverse } from "neotraverse/modern"
import objectPath from "object-path"
import type { IntlFormatters } from "react-intl"
import type { ReadonlyDeep } from "type-fest"
import { isWhitespaceParagraphNode, trimRichTextWhiteSpace } from "../../../components/RichTextEditor/util/common.js"
import { getHighlightColorForRelativeTimeLeft } from "../../legal/helpers.js"

// gpt-4o suggested this value
export const DRAFT_SAVE_DEBOUNCE_DELAY = 300

export const WORKFLOW_RUN_STEP_FORM_ID = "workflow-run-step-form"

export const WORKFLOW_TIMELINE_HIDE_STORAGE_KEY = "workflow-timeline-hidden"

export const WORKFLOW_SIDE_BAR_MINIMIZED_STORAGE_KEY = "workflow-sidebar-minimized"

export type WorkflowRunWithExternalFlag = WorkflowRun & { is_external: boolean }

export const workflowRunDueDateColor = (run: Pick<WorkflowRun, "target_date" | "created_at" | "status">) => {
  // Due date will be colored black if the request is not in progress
  return run.status !== "in_progress"
    ? "black"
    : // Due date will be colored red if it is in the past - the request is overdue
      Temporal.PlainDate.compare(Temporal.Now.plainDateISO(), run.target_date) > 0
      ? "error"
      : getHighlightColorForRelativeTimeLeft(run.created_at, run.target_date)
}

export const canEditWorkflowRunStep = (
  whoami: UserWithOrganization | undefined,
  step: Pick<WorkflowRunStep, "owner" | "status">,
  run: Pick<WorkflowRun, "status">
): boolean => {
  if (!workflowRunIsActive(run)) {
    return false
  }
  return workflowRunStepIsActive(step)
}

export const canEditWorkflowRunStepUsers = (
  step: Pick<WorkflowRunStep, "status">,
  run: Pick<WorkflowRun, "status">
): boolean => {
  if (!workflowRunIsActive(run)) {
    return false
  }
  return workflowRunStepIsActive(step)
}

export const canSubmitWorkflowRunStep = (
  whoami: UserWithOrganization | undefined,
  step: Pick<WorkflowRunStep, "owner" | "status">,
  run: Pick<WorkflowRun, "status">
): boolean => {
  if (!workflowRunIsActive(run)) {
    return false
  }
  if (hasPermission(whoami?.roles, "workflow_run_step:submit:intake") || whoami?.id === step.owner?.id) {
    return step.status === "pending" || step.status === "in_progress"
  }
  return false
}

export const canApproveWorkflowRunStep = (
  whoami: UserWithOrganization | undefined,
  step: Pick<WorkflowRunStep, "approval_steps" | "status" | "type">,
  run: Pick<WorkflowRun, "status">
): boolean => {
  if (!workflowRunIsActive(run) || !step.approval_steps[0]) {
    return false
  }
  const currentApprover = getCurrentApprovalStep(step.approval_steps)
  if (
    // Can approve any workflow step
    hasPermission(whoami?.roles, "workflow:approve:any") ||
    // Can approve any workflow step of a specific type
    isApproverForStepType(step.type, whoami?.roles) ||
    // Is the approver for this step
    (currentApprover && currentApprover.approvers.some((a) => a.user.id === whoami?.id))
  ) {
    return step.status === "submitted"
  }
  return false
}

export const canUnapproveWorkflowRunStep = (
  whoami: UserWithOrganization | undefined,
  step: Pick<WorkflowRunStep, "approval_steps" | "status">,
  run: Pick<WorkflowRun, "status">
): boolean => {
  if (!workflowRunIsActive(run) || !step.approval_steps[0]) {
    return false
  }
  if (
    hasPermission(whoami?.roles, "workflow:approve:any") ||
    step.approval_steps.some((approvalStep) => approvalStep.approvers.some((a) => a.user.id === whoami?.id))
  ) {
    return step.status === "approved"
  }
  return false
}

export const canReopenCompletedWorkflowRunStep = (
  whoami: UserWithOrganization | undefined,
  step: Pick<WorkflowRunStep, "status" | "owner">,
  run: Pick<WorkflowRun, "status" | "owner">
): boolean => {
  if (!workflowRunIsActive(run) || step.status !== "completed") {
    return false
  }
  return (
    step.owner?.id === whoami?.id ||
    run.owner.id === whoami?.id ||
    hasPermission(whoami?.roles, "workflow_run_step:submit:intake")
  )
}

/** Whether or not the step is in a state where an eligible approver can approve step fields */
export const canApproveStepFields = (step: Pick<WorkflowRunStep, "status">): boolean =>
  Boolean(step.status === "in_progress" || step.status === "submitted")

/** When displaying the progress of a field, if true, show the approved fields / total fields. If false, show the completed fields / total fields */
export const showApprovedFieldCounts = (status: WorkflowRunStepStatus): boolean => {
  return Boolean(status === "approved" || status === "submitted")
}

export function isEmptyString(value: unknown): boolean {
  return typeof value === "string" && value.trim() === ""
}

// replace all undefined or whitespace-only strings with null
export const cleanValues = (values: object, rootSchema: ReadonlyDeep<JSONSchema>): unknown => {
  const cleanedValues = new Traverse(values).map((traversal, value) => {
    if (value === undefined) {
      traversal.delete(true)
      return
    }
    if (isEmptyString(value)) {
      return null
    }
    const pathSchema = getSchemaAtPath(rootSchema, traversal.path as Exclude<PropertyKey, symbol>[])
    if (isRichTextType(pathSchema, value)) {
      // Rich text fields should not be recursed into any further
      traversal.block()
      if (!value) {
        return null
      }
      const trimmedRichText = trimRichTextWhiteSpace(value)
      if (trimmedRichText.every((n) => isWhitespaceParagraphNode(n))) {
        return null
      }
      // Trim the value before saving
      return trimmedRichText
    }
    return value
  })
  return cleanedValues
}

/**
 * Fills in the minimal ids and object types along the path of a field to guarantee that it will be merge-able
 * with an existing draft state object.
 */
export const fillIdsAndObjectTypesAlongPath = (
  targetPatchState: object,
  allValues: object,
  path: (string | number)[]
) => {
  for (let i = 0; i < path.length; i++) {
    const pathToValue = path.slice(0, i)
    const valueAtPath = objectPath.get(allValues, pathToValue)
    if (isObjectWithIdAndObjectType(valueAtPath)) {
      if (pathToValue.length > 0) {
        objectPath.set(targetPatchState, `${pathToValue.join(".")}.id`, valueAtPath.id)
        objectPath.set(targetPatchState, `${pathToValue.join(".")}.object_type`, valueAtPath.object_type)
      } else {
        objectPath.set(targetPatchState, "id", valueAtPath.id)
        objectPath.set(targetPatchState, "object_type", valueAtPath.object_type)
      }
    }
  }

  return targetPatchState
}

export function displayWorkflowRunStepStatus(step: Pick<WorkflowRunStep, "status">, intl: IntlFormatters): string {
  switch (step.status) {
    case "pending":
    case "pending_submitted":
      return intl.formatMessage({
        defaultMessage: "Not started",
        description: "The label for the request stage status `pending`",
        id: "requests.run.stage.status.pending",
      })
    case "in_progress":
      return intl.formatMessage({
        defaultMessage: "In progress",
        description: "The label for the request stage status `in_progress`",
        id: "requests.run.stage.status.in_progress",
      })
    case "approved":
      return intl.formatMessage({
        defaultMessage: "Approved",
        description: "The label for the request stage status `approved`",
        id: "requests.run.stage.status.approved",
      })
    case "rejected":
      return intl.formatMessage({
        defaultMessage: "Rejected",
        description: "The label for the request stage status `rejected`",
        id: "requests.run.stage.status.rejected",
      })
    case "cancelled":
      return intl.formatMessage({
        defaultMessage: "Cancelled",
        description: "The label for the request stage status `cancelled`",
        id: "requests.run.stage.status.cancelled",
      })
    case "submitted":
      return intl.formatMessage({
        defaultMessage: "In review",
        description: "The label for the request stage status `submitted`",
        id: "requests.run.stage.status.submitted",
      })
    case "completed":
      return intl.formatMessage({
        defaultMessage: "Completed",
        description: "The label for the request stage status `completed`",
        id: "requests.run.stage.status.completed",
      })
    case "skipped":
      return intl.formatMessage({
        defaultMessage: "Skipped",
        description: "The label for the request stage status `skipped`",
        id: "requests.run.stage.status.skipped",
      })
    default:
      unreachable(step.status)
  }
}

const isApproverForStepType = (stepType: WorkflowStepStandardType, roles: Array<Role> | undefined): boolean => {
  if (!hasPermission(roles, "workflow:approve")) {
    return false
  }
  switch (stepType) {
    case "it":
      return hasPermission(roles, "it:approve")
    case "finance":
      return hasPermission(roles, "finance:approve")
    case "compliance":
      return hasPermission(roles, "compliance:approve")
    case "legal":
      return hasPermission(roles, "legal:approve")
    case "close":
    case "details":
    case "custom":
      return false
    default:
      unreachable(stepType)
  }
}

export const getMinimalRoleForPermission = (permission: Permission) => {
  const roles = ROLES_BY_PERMISSION[permission]
  // Used to decide which role to return
  const rolesInOrder: Record<Role, number> = {
    super_admin: 0,
    admin: 1,
    legal: 2,
    finance: 3,
    compliance: 3,
    it: 3,
    legal_approver: 4,
    compliance_approver: 4,
    it_approver: 4,
    finance_approver: 4,
  }

  let lowestPermissionRole = roles[0]
  roles.forEach((role: Role) => {
    if (rolesInOrder[role] > rolesInOrder[lowestPermissionRole]) {
      lowestPermissionRole = role
    }
  })
  return lowestPermissionRole
}

export const getPermissionRequiredToEditStep = (stepType: WorkflowStepStandardType): Permission => {
  switch (stepType) {
    case "details":
      return "workflow:approve"
    case "compliance":
      return "compliance:approve"
    case "it":
      return "it:approve"
    case "finance":
      return "finance:approve"
    case "legal":
      return "legal:approve"
    case "close":
      return "workflow:approve"
    case "custom":
      return "workflow:approve"
    default:
      unreachable(stepType)
  }
}

/**
 * Ordering used to break ties for ordering between steps in parallel
 */
export const WORKFLOW_RUN_STEP_TYPE_ORDER_MAP: Record<WorkflowStepStandardType, number> = {
  details: 0,
  finance: 1,
  legal: 2,
  compliance: 3,
  it: 4,
  close: 5,
  custom: 6,
}

export const workflowRunStepSortFunction = (
  a: { type: WorkflowStepStandardType },
  b: { type: WorkflowStepStandardType }
) => {
  return WORKFLOW_RUN_STEP_TYPE_ORDER_MAP[a.type] - WORKFLOW_RUN_STEP_TYPE_ORDER_MAP[b.type]
}

export interface OrganizationActor {
  /* The display name to show for the organization */
  displayName: string
  /* A gcs asset string */
  logo?: string
}

export interface GetOrganizationActorProps {
  /**
   * This function is called to get information required to show an organization as an actor for when actor information is missing.
   * Currently this is used for cross org mentions to show that an anonymous user from the seller org is the actor.
   * @returns an organization actor to show in place of the missing actor
   */
  getOrganizationActorWhenActorMissing?: () => OrganizationActor
}

export interface GetLogoForOrganizationProps {
  /**
   * This function is called to determine what logo if any to show for things relating to an organization (ex: logos on mentions when collaborating cross org)
   * @param organizationId The organization id of that you want to get the logo for.
   * @returns a string GCS file name for the logo to show if a logo should be shown for this organization to the current user otherwise undefined
   */
  getLogoToShowByOrganizationId?: (organizationId: string) => string | undefined
}
