import { type FieldCategory, type VendorDetails, type VendorPatch, type VendorTool } from "@brm/schema-types/types.js"
import { FieldCategorySchema } from "@brm/schemas"
import { mutableClone } from "@brm/util/mutable.js"
import { getTitle } from "@brm/util/schema.js"
import { Card, CardBody, FormLabel, HStack, Heading, Spacer, Stack, useToast } from "@chakra-ui/react"
import { useCallback, useMemo } from "react"
import type { DefaultValues } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import type { Descendant } from "slate"
import { usePatchVendorV1ByIdMutation } from "../../app/services/generated-api.js"
import ToolCell from "../../components/DataTable/CellRenderer/ToolCell.js"
import DynamicForm, { type DynamicFormProps } from "../../components/DynamicForm/DynamicForm.js"
import { FORM_MAX_WIDTH } from "../../components/DynamicForm/utils.js"
import RichTextDisplay from "../../components/RichTextEditor/RichTextDisplay.js"
import { EMPTY_RICH_TEXT_BODY, isEmptyRichText } from "../../components/RichTextEditor/util/common.js"
import { useObjectSchema } from "../../util/use-schema.js"
import VendorComplianceStatusBadge from "./status/VendorComplianceStatusBadge.js"
import VendorFinanceStatusBadge from "./status/VendorFinanceStatusBadge.js"
import VendorItStatusBadge from "./status/VendorItStatusBadge.js"

/**
 * Checks if any tool in the array has a non-empty rich text field for the given field name
 */
const hasToolWithNonEmptyField = (tools: VendorTool[], fieldName: keyof VendorTool): boolean => {
  return tools.some((tool) => !!tool[fieldName] && !isEmptyRichText(tool[fieldName] as Descendant[]))
}

/**
 * Checks if a vendor field is missing content that exists in the corresponding tool field
 * Used to identify when vendor-level fields should be populated from tool-level content
 */
const isMissingVendorFieldWithToolContent = ({
  vendorField,
  toolField,
  vendorValue,
  tools,
  formFields,
}: {
  vendorField: keyof VendorDetails
  toolField: keyof VendorTool
  vendorValue: Descendant[] | undefined
  tools: VendorTool[]
  formFields: DynamicFormProps<VendorPatch>["formFields"]
}): boolean => {
  return (
    formFields.some((field) => field.object_type === "Vendor" && field.field_name === vendorField) &&
    (!vendorValue || isEmptyRichText(vendorValue)) &&
    hasToolWithNonEmptyField(tools, toolField)
  )
}

interface Props {
  vendor: VendorDetails
  category: FieldCategory | undefined
  formFields: DynamicFormProps<VendorPatch>["formFields"] | undefined
}

export default function VendorCategory({ vendor, category, formFields }: Props) {
  const toast = useToast()
  const intl = useIntl()

  const vendorSchema = useObjectSchema("Vendor")

  const initialFormValues = useMemo(() => {
    const initialFormValues: VendorPatch = mutableClone(vendor)
    return initialFormValues as DefaultValues<VendorPatch>
  }, [vendor])

  const [updateVendor] = usePatchVendorV1ByIdMutation()

  const title = useMemo(() => {
    const fieldCategorySchema = FieldCategorySchema.anyOf.find((schema) => schema.const === category)
    return getTitle(
      category ??
        intl.formatMessage({
          defaultMessage: "General",
          description: "General category title",
          id: "general",
        }),
      fieldCategorySchema
    )
  }, [category, intl])

  const submit = useCallback(
    async (outputs: VendorPatch) => {
      try {
        await updateVendor({ id: vendor.id, vendorPatch: outputs }).unwrap()
        toast({
          description: intl.formatMessage(
            {
              id: "vendor.category.saved",
              defaultMessage: "{category} saved",
              description: "Vendor category saved toast message",
            },
            { category: title }
          ),
          status: "success",
        })
      } catch (err) {
        toast({
          description: intl.formatMessage(
            {
              id: "vendor.category.error",
              defaultMessage: "Error saving {category}",
              description: "Vendor category error toast message",
            },
            { category: title }
          ),
          status: "error",
        })
        throw err
      }
    },
    [intl, title, toast, vendor.id, updateVendor]
  )

  const documentDownloadURL = useCallback(
    (path: (string | number)[]) =>
      new URL(`/vendor/v1/${vendor.id}/${path.join("/")}/content`, import.meta.env.VITE_API_BASE_URL).href,
    [vendor.id]
  )

  if (!formFields || !vendorSchema) {
    return null
  }

  // Check if vendor is missing problems_addressed field but has tool overview content
  const vendorMissingProblemsAddressedAndToolHasOverview = isMissingVendorFieldWithToolContent({
    vendorField: "problems_addressed",
    toolField: "overview",
    vendorValue: vendor.problems_addressed as Descendant[],
    tools: vendor.tools,
    formFields,
  })

  // Check if vendor is missing selection rationale but tools have it
  const vendorMissingSelectionRationaleAndToolHasSelectionRationale = isMissingVendorFieldWithToolContent({
    vendorField: "selection_rationale",
    toolField: "selection_rationale",
    vendorValue: vendor.selection_rationale as Descendant[],
    tools: vendor.tools,
    formFields,
  })

  // Check if vendor is missing ROI analysis but tools have it
  const vendorMissingRoiAnalysisAndToolHasRoiAnalysis = isMissingVendorFieldWithToolContent({
    vendorField: "roi_analysis",
    toolField: "roi_analysis",
    vendorValue: vendor.roi_analysis as Descendant[],
    tools: vendor.tools,
    formFields,
  })

  // Filter out vendor fields that are already covered by tool content
  const filteredVendorFields = formFields.filter((field) => {
    // Remove problems_addressed if tool has overview
    if (
      vendorMissingProblemsAddressedAndToolHasOverview &&
      field.object_type === "Vendor" &&
      field.field_name === "problems_addressed" &&
      field.is_custom === false
    ) {
      return false
    }
    // Remove selection rationale if tool has it
    if (
      vendorMissingSelectionRationaleAndToolHasSelectionRationale &&
      field.object_type === "Vendor" &&
      field.field_name === "selection_rationale" &&
      field.is_custom === false
    ) {
      return false
    }
    // Remove ROI analysis if tool has it
    if (
      vendorMissingRoiAnalysisAndToolHasRoiAnalysis &&
      field.object_type === "Vendor" &&
      field.field_name === "roi_analysis" &&
      field.is_custom === false
    ) {
      return false
    }
    return true
  })

  return (
    <HStack justifyContent="center">
      <Stack maxWidth={FORM_MAX_WIDTH} flex={1}>
        <HStack justifyContent="space-between">
          <Heading size="xs">{title}</Heading>
          <Spacer />
          {category === "it" ? (
            <VendorItStatusBadge status={vendor.it_status} editVendorId={vendor.id} />
          ) : category === "compliance" ? (
            <VendorComplianceStatusBadge status={vendor.compliance_status} editVendorId={vendor.id} />
          ) : category === "finance" ? (
            <VendorFinanceStatusBadge status={vendor.finance_status} editVendorId={vendor.id} />
          ) : null}
        </HStack>
        {formFields.length === 0 ? (
          <FormattedMessage
            defaultMessage="No editable fields in this category"
            description="Helper message when there are no editable fields in this category"
            id="vendor.category.no-editable-fields"
          />
        ) : (
          <>
            {vendorMissingProblemsAddressedAndToolHasOverview && (
              <Stack>
                <FormLabel>
                  <FormattedMessage
                    defaultMessage="Problems addressed by tools"
                    description="Problems addressed by tools label"
                    id="vendor.category.overview"
                  />
                </FormLabel>
                {vendor.tools.map((tool) => (
                  <Card key={tool.id}>
                    <CardBody as={HStack}>
                      <ToolCell tool={tool} />
                      <RichTextDisplay
                        key={tool.id}
                        content={(tool.overview as Descendant[]) ?? EMPTY_RICH_TEXT_BODY}
                      />
                    </CardBody>
                  </Card>
                ))}
              </Stack>
            )}
            {vendorMissingSelectionRationaleAndToolHasSelectionRationale && (
              <Stack>
                <FormLabel>
                  <FormattedMessage
                    defaultMessage="Selection rationale of tools"
                    description="Selection rationale of tools label"
                    id="vendor.category.selection-rationale"
                  />
                </FormLabel>
                {vendor.tools.map((tool) => (
                  <Card key={tool.id}>
                    <CardBody as={HStack}>
                      <ToolCell tool={tool} />
                      <RichTextDisplay
                        key={tool.id}
                        content={(tool.selection_rationale as Descendant[]) ?? EMPTY_RICH_TEXT_BODY}
                      />
                    </CardBody>
                  </Card>
                ))}
              </Stack>
            )}
            {vendorMissingRoiAnalysisAndToolHasRoiAnalysis && (
              <Stack>
                <FormLabel>
                  <FormattedMessage
                    defaultMessage="ROI analysis of tools"
                    description="ROI analysis of tools label"
                    id="vendor.category.roi-analysis"
                  />
                </FormLabel>
                {vendor.tools.map((tool) => (
                  <Card key={tool.id}>
                    <CardBody as={HStack}>
                      <ToolCell tool={tool} />
                      <RichTextDisplay
                        key={tool.id}
                        content={(tool.roi_analysis as Descendant[]) ?? EMPTY_RICH_TEXT_BODY}
                      />
                    </CardBody>
                  </Card>
                ))}
              </Stack>
            )}
            <DynamicForm<VendorPatch>
              initialFormValues={initialFormValues}
              rootSchema={vendorSchema}
              formFields={filteredVendorFields}
              onSubmit={submit}
              documentDownloadURL={documentDownloadURL}
              isEditing={true}
            />
          </>
        )}
      </Stack>
    </HStack>
  )
}
