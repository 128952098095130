import type { CreditCardListItem, CreditCardStatus, WorkflowRunMinimal } from "@brm/schema-types/types.js"
import { CreditCardStatusSchema } from "@brm/schemas"
import type { EnumTypeSchema } from "@brm/util/schema.js"
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AvatarGroup,
  Box,
  Button,
  Card as ChakraCard,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Spacer,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { useRef } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import {
  usePostCreditCardV1LockByIdMutation,
  usePostCreditCardV1UnlockByIdMutation,
  type CreditCardWithVendors,
} from "../app/services/generated-api.js"
import { getPublicImageGcsUrl } from "../util/url.js"
import { EnumBadge } from "./EnumBadge.js"
import FeaturedIcon from "./FeaturedIcon/FeaturedIcon.js"
import { FormattedCurrency } from "./FormattedCurrency.js"
import { FormattedDate } from "./FormattedDate.js"
import { IconButtonWithTooltip } from "./IconButtonWithTooltip.js"
import {
  CancelIcon,
  CreditCardIcon,
  FilterFunnelIcon,
  InfoIcon,
  MoreMenuIcon,
  RequestIcon,
  ShareIcon,
  UnlockedIcon,
} from "./icons/icons.js"
import { VendorLogo } from "./icons/Logo.js"
import { Link } from "./Link.js"
import OverflownText from "./OverflownText.js"
import { Timestamp } from "./Timestamp.js"

interface CreditCardProps {
  filterTransactions?: (cardId: string) => void
  selected?: boolean
  isFetching?: boolean
  onLock?: () => void
  onUnlock?: () => void
}

export function CreditCard({
  spend_limit,
  spend_limit_interval,
  display_name,
  owner,
  last_four,
  status,
  last_transaction,
  id,
  filterTransactions,
  selected = false,
  vendors,
  external_card_id,
  external_limit_id,
  workflow_run_source,
  isFetching,
  onLock,
  onUnlock,
  valid_until,
}: CreditCardWithVendors &
  CreditCardProps & { workflow_run_source?: Pick<WorkflowRunMinimal, "id" | "display_name"> | null }) {
  const intl = useIntl()
  const toast = useToast()
  const theme = status === "active" || status === "shipped" ? "light" : "dark"
  const background = theme === "light" ? "linear-gradient(180deg, #F9FAFB 0%, #EDF0F3 100%)" : "gray.500"
  const textColor = theme === "light" ? "gray.800" : "white"
  const lockCardDisclosure = useDisclosure()
  const cancelRef = useRef<HTMLButtonElement>(null)
  const [lockCreditCard, { isLoading: isLocking }] = usePostCreditCardV1LockByIdMutation()
  const [unlockCreditCard, { isLoading: isUnlocking }] = usePostCreditCardV1UnlockByIdMutation()

  const handleLock = async () => {
    lockCardDisclosure.onClose()
    onLock?.()
    try {
      await lockCreditCard({ id })
    } catch {
      toast({
        title: "Failed to lock card",
        description: "An error occurred while locking the card",
        status: "error",
      })
    }
  }

  return (
    <>
      <ChakraCard
        borderRadius="3xl"
        width="280px"
        gap={2}
        background={background}
        textColor={textColor}
        variant="unstyled"
        borderColor={theme === "light" ? "brand.700" : "gray.200"}
        borderStyle="solid"
        borderWidth={2}
        margin={0}
        flexShrink={0}
        height="fit-content"
      >
        <Stack padding={4} pb={1}>
          <HStack gap={1}>
            <OverflownText fontWeight="semibold" fontSize="lg">
              {display_name ??
                intl.formatMessage({
                  defaultMessage: "Deleted Credit Card",
                  description: "Unknown credit card display name",
                  id: "creditCard.deleted_display_name",
                })}
            </OverflownText>
            <Spacer />
            {isLocking || isUnlocking || isFetching ? (
              <Spinner size="sm" />
            ) : (
              <EnumBadge
                value={status?.toString() ?? undefined}
                schema={CreditCardStatusSchema as unknown as EnumTypeSchema<CreditCardStatus>}
              />
            )}
            {filterTransactions && (
              <IconButtonWithTooltip
                variant="unstyled"
                color={textColor}
                size="sm"
                icon={<Icon as={FilterFunnelIcon} fill={selected ? textColor : undefined} />}
                label={intl.formatMessage({
                  id: "creditCard.table.action.filterTransactions.label",
                  description: "The ARIA label for the filter transactions button in the credit card table",
                  defaultMessage: "Filter transactions",
                })}
                onClick={() => filterTransactions(id)}
              />
            )}
            <Menu>
              <MenuButton
                size="sm"
                color={textColor}
                variant="unstyled"
                as={IconButton}
                aria-label={intl.formatMessage({
                  id: "creditCard.table.action.actions.label",
                  description: "The ARIA label for the actions dropdown button in the credit card table",
                  defaultMessage: "Actions",
                })}
                icon={<Icon as={MoreMenuIcon} boxSize={5} />}
              />
              <Portal>
                <MenuList color="gray.700">
                  {status !== "locked" ? (
                    <MenuItem
                      isDisabled={!external_card_id || !external_limit_id}
                      icon={<Icon as={CancelIcon} color="error.600" />}
                      onClick={lockCardDisclosure.onOpen}
                      color="error.600"
                    >
                      <FormattedMessage
                        defaultMessage="Lock card"
                        description="The lock label in card menulist"
                        id="creditCard.table.action.lock"
                      />
                    </MenuItem>
                  ) : (
                    <MenuItem
                      isDisabled={!external_card_id || !external_limit_id}
                      icon={<Icon as={UnlockedIcon} />}
                      onClick={async () => {
                        try {
                          await unlockCreditCard({ id })
                          onUnlock?.()
                        } catch {
                          toast({ title: "Failed to unlock card", status: "error" })
                        }
                      }}
                    >
                      <FormattedMessage
                        defaultMessage="Unlock card"
                        description="The unlock label in card menulist"
                        id="creditCard.table.action.unlock"
                      />
                    </MenuItem>
                  )}
                  {workflow_run_source && (
                    <MenuItem
                      as={Link}
                      icon={<Icon as={RequestIcon} />}
                      to={`/requests/${workflow_run_source.id}`}
                      target="_blank"
                    >
                      <FormattedMessage
                        defaultMessage="View issuance request"
                        description="The label for viewing the issuance request in card menulist"
                        id="creditCard.table.action.viewIssuanceRequest"
                      />
                    </MenuItem>
                  )}
                  {external_limit_id && (
                    <MenuItem
                      as={Link}
                      icon={<Icon as={ShareIcon} />}
                      to={`https://app.ramp.com/cards/virtual-cards/virtual-card/${external_limit_id}`}
                      target="_blank"
                    >
                      <FormattedMessage
                        defaultMessage="View in Ramp"
                        description="The label for viewing the credit card in the integration provider’s portal in card menulist"
                        id="creditCard.table.action.viewInProvider"
                      />
                    </MenuItem>
                  )}
                </MenuList>
              </Portal>
            </Menu>
          </HStack>
          <HStack>
            <Text>
              {status === "terminated" ? last_four : `**** ${last_four ?? ""}`}
              {owner?.display_name && ` • ${owner.display_name}`}
            </Text>
          </HStack>

          <HStack>
            {spend_limit && spend_limit_interval ? (
              <FormattedMessage
                id="creditCard.table.creditCardSpendLimit"
                defaultMessage="{spendLimit} limit/{spendInterval}"
                description="Spend limit information for the assigned credit card in the workflow run step header"
                values={{
                  spendLimit: (
                    <Text fontSize="lg">
                      <FormattedCurrency currencyAmount={spend_limit} />
                    </Text>
                  ),
                  spendInterval: spend_limit_interval,
                }}
              />
            ) : (
              <Text fontSize="lg">
                <FormattedMessage
                  id="creditCard.table.noSpendLimit"
                  defaultMessage="No spend limit"
                  description="Description of no spend limit on credit card"
                />
              </Text>
            )}
          </HStack>
        </Stack>
        <Divider borderColor="gray.300" />
        <HStack padding={3} pt={1} fontSize="xs" width="full">
          <Flex alignSelf={valid_until ? "start" : "center"} height="full">
            <Icon as={InfoIcon} />
          </Flex>
          <Stack gap={1}>
            <OverflownText>
              {last_transaction?.transacted_at ? (
                <FormattedMessage
                  id="creditCard.table.lastCharged"
                  defaultMessage="Last charge: {timestamp}"
                  description="Description of most recent credit card charge"
                  values={{
                    timestamp: <Timestamp dateTime={last_transaction?.transacted_at} />,
                  }}
                />
              ) : workflow_run_source ? (
                <FormattedMessage
                  id="creditCard.table.noCharge"
                  defaultMessage="Issued on {request}"
                  description="Description of no charges posted on credit card"
                  values={{
                    request: (
                      <Link to={`/requests/${workflow_run_source.id}`} target="_blank" fontWeight="medium">
                        {workflow_run_source.display_name}
                      </Link>
                    ),
                  }}
                />
              ) : (
                <FormattedMessage
                  id="creditCard.table.noCharge"
                  defaultMessage="No charge posted"
                  description="Description of no charges posted on credit card"
                />
              )}
            </OverflownText>

            {valid_until && (
              <FormattedMessage
                id="creditCard.table.validUntil"
                defaultMessage="Until {date}"
                description="Description of the valid until date on the credit card"
                values={{ date: <FormattedDate value={valid_until} year="numeric" month="numeric" day="numeric" /> }}
              />
            )}
          </Stack>

          <Spacer />
          {vendors && vendors.length > 0 ? (
            <Tooltip
              label={intl.formatMessage(
                {
                  id: "creditCard.table.chargedBy",
                  defaultMessage: "{count} {count, plural, one {Vendor} other {Vendors}} detected",
                  description: "Tooltip label for the charged by section in the credit card table",
                },
                { count: vendors.length }
              )}
            >
              <AvatarGroup>
                {vendors.slice(0, 3).map((vendor) => {
                  const vendorLogo = getPublicImageGcsUrl(vendor.image_asset?.gcs_file_name)
                  return <VendorLogo boxSize={5} key={vendor.id} logo={vendorLogo} backgroundColor="gray.200" />
                })}
              </AvatarGroup>
            </Tooltip>
          ) : (
            <Box height={5} />
          )}
        </HStack>
      </ChakraCard>

      <AlertDialog
        isOpen={lockCardDisclosure.isOpen}
        leastDestructiveRef={cancelRef}
        onClose={lockCardDisclosure.onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>
              <FormattedMessage
                id="creditCard.lock.confirmHeader"
                description="Header for credit card lock confirmation dialog"
                defaultMessage="Lock Credit Card"
              />
            </AlertDialogHeader>

            <AlertDialogBody>
              {vendors && vendors.length > 0 && (
                <Stack spacing={2} mb={4}>
                  <FormattedMessage
                    id="creditCard.terminate.vendorCharges"
                    description="Message showing number of vendor charges found on credit card"
                    defaultMessage="{count} {count, plural, one {Vendor charge} other {Vendor charges}} found on this card:"
                    values={{ count: vendors.length }}
                  />
                  <Stack paddingY={2}>
                    {vendors.slice(0, 5).map((vendor) => (
                      <Link target="_blank" key={vendor.id} to={`/vendors/${vendor.id}`}>
                        <HStack>
                          <VendorLogo
                            logo={getPublicImageGcsUrl(vendor.image_asset?.gcs_file_name)}
                            backgroundColor="white"
                          />
                          <Text>{vendor.display_name}</Text>
                        </HStack>
                      </Link>
                    ))}
                  </Stack>
                  {vendors.length > 5 && (
                    <FormattedMessage
                      id="creditCard.lock.moreVendorCharges"
                      description="Message showing more vendor charges found on credit card"
                      defaultMessage="And {count} more..."
                      values={{ count: vendors.length - 5 }}
                    />
                  )}
                </Stack>
              )}
              <FormattedMessage
                id="creditCard.lock.confirmMessage"
                description="Confirmation message for credit card termination"
                defaultMessage="Are you sure you want to lock this credit card? Future charges will be blocked once locked."
              />
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={lockCardDisclosure.onClose}>
                <FormattedMessage
                  id="creditCard.lock.cancel"
                  description="Cancel button text for credit card termination"
                  defaultMessage="Cancel"
                />
              </Button>
              <Button colorScheme="red" onClick={handleLock} ml={3}>
                <FormattedMessage
                  id="creditCard.lock.confirm"
                  description="Confirm button text for credit card lock"
                  defaultMessage="Lock"
                />
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export const InlineCreditCard = ({
  display_name,
  last_four,
  owner,
  last_transaction,
  vendors,
  status,
}: Pick<CreditCardListItem, "display_name" | "last_four" | "owner" | "last_transaction" | "vendors" | "status">) => {
  const intl = useIntl()
  return (
    <HStack
      borderColor="gray.300"
      borderWidth={1}
      borderStyle="solid"
      borderRadius="lg"
      background="gray.50"
      padding={2}
    >
      <FeaturedIcon icon={<Icon as={CreditCardIcon} />} backgroundColor="brand.100" />
      <Stack gap={1}>
        <Text
          fontSize="sm"
          fontWeight="medium"
        >{`${display_name ?? intl.formatMessage({ defaultMessage: "Deleted Credit Card", description: "Unknown credit card display name" })} ${last_four ? `****${last_four}` : ""} ${owner?.display_name ? `• ${owner.display_name}` : ""}`}</Text>
        <Text fontSize="xs" color="gray.600">
          {last_transaction?.transacted_at ? (
            <FormattedMessage
              id="creditCard.table.lastCharged"
              defaultMessage="Last charge: {timestamp}"
              description="Description of most recent credit card charge"
              values={{
                timestamp: <Timestamp dateTime={last_transaction?.transacted_at} />,
              }}
            />
          ) : (
            <FormattedMessage
              id="creditCard.table.noCharge"
              defaultMessage="No charge posted"
              description="Description of no charges posted on credit card"
            />
          )}
        </Text>
      </Stack>
      <Spacer />
      {vendors && vendors.length > 0 && (
        <Tooltip
          label={intl.formatMessage(
            {
              id: "creditCard.table.chargedBy",
              defaultMessage: "{count} {count, plural, one {Vendor} other {Vendors}} detected",
              description: "Tooltip label for the charged by section in the credit card table",
            },
            { count: vendors.length }
          )}
        >
          <AvatarGroup>
            {vendors.slice(0, 3).map((vendor) => {
              const vendorLogo = getPublicImageGcsUrl(vendor.image_asset?.gcs_file_name)
              return <VendorLogo key={vendor.id} logo={vendorLogo} backgroundColor="gray.200" />
            })}
          </AvatarGroup>
        </Tooltip>
      )}
      <EnumBadge
        value={status?.toString() ?? undefined}
        schema={CreditCardStatusSchema as unknown as EnumTypeSchema<CreditCardStatus>}
      />
    </HStack>
  )
}
