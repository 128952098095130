import { Temporal } from "@js-temporal/polyfill"
import { fetchEventSource } from "@microsoft/fetch-event-source"
import { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { generatedApi } from "../../app/services/generated-api.js"
import { log } from "../../util/logger.js"

// Note that there is a matching heartbeat timer of 15_000 in the api subscribe/v1/ route
const CONNECTION_RESET_TIMEOUT = 30_000
const RETRY_MS = 10_000

export const useSubscribe = (url: string, log_tag: string) => {
  const dispatch = useDispatch()

  const abortControllerRef = useRef<AbortController>()
  const connectionTimerRef = useRef<NodeJS.Timeout>()

  const [reset, setReset] = useState({
    timestamp: Temporal.Now.instant().toString(),
  })

  useEffect(() => {
    // abort the previous connection
    if (abortControllerRef.current !== undefined) {
      abortControllerRef.current.abort()
    }

    abortControllerRef.current = new AbortController()

    const resetConnectionTimer = (resetOrigin: string) => {
      clearTimeout(connectionTimerRef.current)
      connectionTimerRef.current = setTimeout(() => {
        log.info(`timeout was triggered! abort and reset: ${resetOrigin}`)
        abortControllerRef.current?.abort()
        clearTimeout(connectionTimerRef.current)
        setReset(() => ({
          timestamp: Temporal.Now.instant().toString(),
        }))
      }, CONNECTION_RESET_TIMEOUT)
    }

    // https://github.com/Azure/fetch-event-source
    void fetchEventSource(url, {
      method: "GET",
      credentials: "include",
      openWhenHidden: true,
      keepalive: true,
      signal: abortControllerRef.current.signal,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Accept: "text/event-stream",
        ["Content-Type"]: "application/json",
      },
      async onopen(res) {
        if (res.ok && res.status === 200) {
          log.info(`[BRM] subscription opened: ${log_tag}`)
          resetConnectionTimer("onOpen")
        } else if (res.status === 401) {
          // login?
        } else {
          const errorMessage = `pubsub sse connection failure: ${log_tag}`
          log.error(errorMessage, res)
          throw new Error(errorMessage)
        }
      },
      onmessage(e) {
        resetConnectionTimer("onMessage")
        if (e.event === "invalidate") {
          log.info(`[BRM] invalidate: ${log_tag}`, e.data)
          const tags = JSON.parse(e.data)
          if (tags && Array.isArray(tags)) {
            dispatch(generatedApi.util.invalidateTags(tags))
          } else {
            log.error("invalidate tags is not an array", tags)
          }
        }
        if (e.event === "heartbeat") {
          const data = JSON.parse(e.data)
          log.info(`[BRM] ${data?.timestamp}`)
        }
      },
      onclose() {
        // throw a TypeError to retry
        throw new TypeError("connection was closed")
      },
      onerror(err: Error) {
        if (err.name === "TypeError") {
          return RETRY_MS
        }
        log.error(`unexpected fatal error in useSubscribe: ${log_tag}`, err)
        throw err
      },
    })

    return () => {
      abortControllerRef.current?.abort()
      clearTimeout(connectionTimerRef.current)
    }
  }, [reset, dispatch, log_tag, url])
}
