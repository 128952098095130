import type { DateString, Task } from "@brm/schema-types/types.js"
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import { useCallback, useMemo, useState, type FC } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { usePostNotificationV1TaskSnoozeMutation } from "../../../app/services/generated-api.js"
import Button from "../../../components/Button/Button.js"
import { ButtonStyles } from "../../../components/Button/types.js"
import CalendarWithDatePanel from "../../../components/Calendar/CalendarWithDatePanel.js"
import type { DateShortcut } from "../../../util/form.js"

interface TaskSnoozeModalProps {
  isOpen: boolean
  onClose: () => void
  task: Task
  maxDate: DateString
}
const TaskSnoozeModal: FC<TaskSnoozeModalProps> = ({ isOpen, onClose, task, maxDate }) => {
  const [snoozeTask] = usePostNotificationV1TaskSnoozeMutation()
  const [date, setDate] = useState<DateString | null>(null)
  const intl = useIntl()

  const tomorrow = Temporal.Now.plainDateISO().add({ days: 1 }).toString()

  if (task.type !== "agreement_renewal") {
    throw new Error("Not a snoozable task")
  }

  const onCloseWrapper = useCallback(() => {
    setDate(null)
    onClose()
  }, [onClose])

  const options: DateShortcut[] = useMemo(() => {
    const optionalIntervals: DateShortcut[] = [
      {
        displayName: intl.formatMessage({
          id: "inbox.task.snooze.tomorrow",
          defaultMessage: "Tomorrow",
          description: "Snooze option for tomorrow",
        }),
        date: Temporal.Now.plainDateISO().add({ days: 1 }).toString(),
      },
      {
        displayName: intl.formatMessage({
          id: "inbox.task.snooze.week",
          defaultMessage: "In a week",
          description: "Snooze option for one week",
        }),
        date: Temporal.Now.plainDateISO().add({ days: 7 }).toString(),
      },
      ...[15, 30, 60].map((days) => ({
        displayName: intl.formatMessage(
          {
            id: "inbox.task.snooze.days",
            defaultMessage: "In {days} days",
            description: "Snooze option for specified number of days",
          },
          { days }
        ),
        date: Temporal.Now.plainDateISO().add({ days }).toString(),
      })),
    ]
    const optionList: DateShortcut[] = []

    for (const interval of optionalIntervals) {
      if (Temporal.PlainDate.compare(Temporal.PlainDate.from(maxDate), Temporal.PlainDate.from(interval.date)) >= 0) {
        optionList.push({ displayName: interval.displayName, date: interval.date })
      }
    }
    return optionList
  }, [intl, maxDate])

  return (
    <Modal scrollBehavior="inside" isOpen={isOpen} onClose={onCloseWrapper} size="lg" closeOnOverlayClick={true}>
      <ModalOverlay />
      <ModalContent height={440}>
        <ModalHeader>
          <FormattedMessage defaultMessage="Snooze until?" description="Snooze modal header" id="inbox.task.snooze" />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody borderBottom="none">
          <CalendarWithDatePanel
            minDate={tomorrow}
            maxDate={maxDate}
            value={date?.toString() || null}
            onChange={(date) => {
              setDate(date)
            }}
            dateShortcuts={options}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            isDisabled={!date}
            onClick={async () => {
              if (!date) return
              await snoozeTask({ taskSnoozeBody: { task, snoozedUntil: date } })
              onClose()
            }}
            buttonStyles={ButtonStyles.SolidBrand}
            size="md"
            label="Confirm"
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default TaskSnoozeModal
